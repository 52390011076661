import React from "react"
import { useIntl, FormattedMessage, navigate } from "gatsby-plugin-intl"
import { useForm } from "react-hook-form"

import Layout, { Section } from "../layout"
import SEO from "../seo"
import Form, { FormRow, FloatingLabelInput } from "../Form"
import { SubmitButton } from "../Button"
import Center from "../Center"
import { sendFormWithData } from "../../helpers/formBot"

interface IFormData {
  firstName: string
  lastName: string
  company: string
  email: string
  phone: string
  message: string
}

export default function Installer() {
  const intl = useIntl()
  const { register, handleSubmit, errors, formState } = useForm<IFormData>()

  const onSubmit = async (data: IFormData) => {
    const result = await sendFormWithData("Partnership Request", data)

    if (!result.ok || result.status !== 200) {
      alert(intl.formatMessage({ id: "installer.formError" }))
      return
    }

    // Redirect
    navigate(
      intl.formatMessage({
        id: "pages.thankYouForContactingUs",
      }),
      {
        state: {
          email: data.email,
        },
      }
    )
  }

  return (
    <Layout skipHeader>
      <SEO lang={intl.locale} title={intl.formatMessage({ id: "title" })} />
      <Section
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <p>
          <FormattedMessage id="installer.weRespondIn" />
        </p>
        <h2>
          <FormattedMessage id="installer.title" />
        </h2>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormRow>
            <FloatingLabelInput className={errors.firstName ? "has-error" : ""}>
              <input
                placeholder={intl.formatMessage({ id: "installer.formName" })}
                type="text"
                name="firstName"
                id="firstName"
                ref={register({
                  required: {
                    value: true,
                    message: intl.formatMessage({ id: "formRequired" }),
                  },
                })}
              />
              <label htmlFor="firstName">
                {intl.formatMessage({ id: "installer.formName" })}
              </label>
              {errors.firstName && (
                <span className="has-error">{errors.firstName.message}</span>
              )}
            </FloatingLabelInput>
            <FloatingLabelInput className={errors.lastName ? "has-error" : ""}>
              <input
                placeholder={intl.formatMessage({
                  id: "installer.formLastName",
                })}
                type="text"
                name="lastName"
                id="lastName"
                ref={register({
                  required: {
                    value: true,
                    message: intl.formatMessage({ id: "formRequired" }),
                  },
                })}
              />
              <label htmlFor="lastName">
                {intl.formatMessage({ id: "installer.formLastName" })}
              </label>
              {errors.lastName && (
                <span className="has-error">{errors.lastName.message}</span>
              )}
            </FloatingLabelInput>
          </FormRow>
          <FormRow>
            <FloatingLabelInput>
              <input
                placeholder={intl.formatMessage({
                  id: "installer.formCompany",
                })}
                name="company"
                id="company"
                ref={register}
              />
              <label htmlFor="company">
                {intl.formatMessage({ id: "installer.formCompany" })}
              </label>
            </FloatingLabelInput>
            <FloatingLabelInput className={errors.email ? "has-error" : ""}>
              <input
                placeholder={intl.formatMessage({ id: "installer.formEmail" })}
                type="text"
                name="email"
                id="email"
                ref={register({
                  required: {
                    value: true,
                    message: intl.formatMessage({ id: "formRequired" }),
                  },
                })}
              />
              <label htmlFor="email">
                {intl.formatMessage({ id: "installer.formEmail" })}
              </label>
              {errors.email && (
                <span className="has-error">{errors.email.message}</span>
              )}
            </FloatingLabelInput>
          </FormRow>
          <FormRow>
            <FloatingLabelInput className={errors.phone ? "has-error" : ""}>
              <input
                placeholder={intl.formatMessage({
                  id: "installer.formPhoneNumber",
                })}
                type="tel"
                name="phone"
                id="phone"
                ref={register({
                  required: {
                    value: true,
                    message: intl.formatMessage({ id: "formRequired" }),
                  },
                })}
              />
              <label htmlFor="phone">
                {intl.formatMessage({ id: "installer.formPhoneNumber" })}
              </label>
              {errors.phone && (
                <span className="has-error">{errors.phone.message}</span>
              )}
            </FloatingLabelInput>
            <div />
          </FormRow>
          <label htmlFor="message">
            {intl.formatMessage({ id: "installer.formMessage" })}
          </label>
          <textarea
            id="message"
            name="message"
            rows={4}
            ref={register}
          ></textarea>
          <br />
          <Center>
            <SubmitButton
              type="submit"
              disabled={formState.isSubmitting}
              value={intl.formatMessage({
                id: formState.isSubmitting
                  ? "installer.formSubmitting"
                  : "installer.formSubmit",
              })}
            />
          </Center>
        </Form>
        <br />
        <h5>
          <FormattedMessage id="installer.formFooter" />
        </h5>
      </Section>
    </Layout>
  )
}
